<!-- <app-layout *ngIf="userType == 1"></app-layout>
<app-header *ngIf="userType != 1"></app-header>
<app-chat-sidebar *ngIf="userType != 1"></app-chat-sidebar> -->

<app-layout></app-layout>
<div class="content">
    <!-- <div class="views-header">
        <div class="breadcrmb"> <span class="breadcrumb-link"> Change Password</span></div>
    </div> -->

    <div class="edit-profile">
        <div class="row justify-content-center">
            <div class="col-md-5">
                <form [formGroup]="passwordForm">
                    <div class="change-pass">
                        <div class="form-group">
                            <label for="">Old Password</label>
                            <mat-form-field appearance="fill" class="w-100">
                                <input matInput [type]="hide ? 'password' : 'text'" value=""
                                    formControlName="oldPassword">
                                <button mat-icon-button matSuffix (click)="hide = !hide"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <div class="alert alert-danger" *ngIf="changed && p.oldPassword.errors">
                                    <span *ngIf="p.oldPassword.errors.required">
                                        Old password is required.
                                    </span>
                                    <span *ngIf="p.oldPassword.errors.minlength">
                                        Password must have at least 8 characters.
                                    </span>
                                </div>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <label for="">New Password</label>
                            <mat-form-field appearance="fill" class="w-100">
                                <input matInput [type]="hide1 ? 'password' : 'text'" value=""
                                    formControlName="newPassword">
                                <button mat-icon-button matSuffix (click)="hide1 = !hide1"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                                    <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <div class="alert alert-danger" *ngIf="changed && p.newPassword.errors">
                                    <span *ngIf="p.newPassword.errors.required">
                                        New password is required.
                                    </span>
                                    <span *ngIf="p.newPassword.errors.minlength">
                                        Password must have at least 8 characters.
                                    </span>
                                    <span *ngIf="p.newPassword.errors.pattern">
                                        Password must contain at least one Uppercase, Lowercase, Digit and One Special symbol
                                    </span>
                                </div>
                            </mat-form-field>
                        </div>

                        <div class="form-group" style="padding-top: 10px;">
                            <label for="">Confirm Password</label>
                            <mat-form-field appearance="fill" class="w-100">
                                <input matInput [type]="hide2 ? 'password' : 'text'" value=""
                                    formControlName="confirmPassword">
                                <button mat-icon-button matSuffix (click)="hide2 = !hide2"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                                    <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <div class="alert alert-danger" *ngIf="changed && p.confirmPassword.errors">
                                    <span *ngIf="p.confirmPassword.errors.required">
                                        Confirm password is required.
                                    </span>
                                    <span *ngIf="p.confirmPassword.errors.minlength">
                                        Password must have at least 8 characters.
                                    </span>
                                    <span *ngIf="p.confirmPassword.errors.pattern">
                                        Password must contain at least one Uppercase, Lowercase, Digit and One Special symbol
                                    </span>
                                </div>
                                <span *ngIf="!match" class="alert alert-danger">
                                    Password doesn't match.
                                </span>
                            </mat-form-field>
                        </div>

                        <div class="form-group text-center">
                            <button class="btn btn-blue" (click)="changePassword()">Change Password</button>
                            <p class="pass-change-msg" *ngIf="passChangeMsg">Password Changed Successfully!</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- </form> -->