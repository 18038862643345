<div class="sidebar">
  <div class="logo"><img src="../../../assets/mf_homecare/left_mf_logo.svg" alt="logo"></div>
  <!-- <div class="logo"><img routerLink="/analytics-dashboard" src="../../../assets/mf_homecare/left_mf_logo.svg" alt="logo"></div> -->

  <div class="header-links">


    <a routerLink="/analytics-dashboard" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/little/dashboard.svg" alt="">
        <img class="white-icon" src="../../../assets/little white/dashboard.svg" alt="" height="24" width="24">
      </span>Reporting</a>


    <a routerLink="/caregiver" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/mf_icons/Care.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/MFcare Icons/White/White Caregiver.svg" alt="" height="24"
          width="24">
        <!-- </span>Manage C & C</a> -->
      </span>Manage Caregivers / &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Companion </a>


    <a routerLink="/manage/clients" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/MFcare Icons/Black/Client.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/MFcare Icons/White/White Client.svg" alt="" height="24" width="24">
      </span>Manage Clients</a>

      <a routerLink="/manage/appoinment" routerLinkActive="active">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/MFcare Icons/Black/Client.svg" alt="" height="24" width="24">
          <img class="white-icon" src="../../../assets/MFcare Icons/White/White Client.svg" alt="" height="24" width="24">
        </span>Appoinment Details</a>



    <a routerLink="/services" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/MFcare Icons/Black/Services.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/MFcare Icons/White/Services.svg" alt="" height="24" width="24">
      </span>Manage Services</a>


    <a routerLink="/manage/diseases" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/MFcare Icons/Black/Manage disease.svg" alt="" height="24"
          width="24">
        <img class="white-icon" src="../../../assets/MFcare Icons/White/Manage disease.svg" alt="" height="24"
          width="24">
      </span>Manage Diseases</a>



    <a routerLink="/manage/convenience" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/MFcare Icons/Black/Manage convenience.svg" alt="" height="24"
          width="24">
        <img class="white-icon" src="../../../assets/MFcare Icons/White/Manage convenience.svg" alt="" height="24"
          width="24">
      </span>Manage Convenience</a>


    <a routerLink="/manage/tutorial" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/MFcare Icons/Black/Manage tutorial.svg" alt="" height="24"
          width="24">
        <img class="white-icon" src="../../../assets/MFcare Icons/White/Manage tutorial.svg" alt="" height="24"
          width="24">
      </span>Manage Tutorial</a>

    <a routerLink="/manage/category" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/MFcare Icons/Black/Manage tutorial.svg" alt="" height="24"
          width="24">
        <img class="white-icon" src="../../../assets/MFcare Icons/White/Manage tutorial.svg" alt="" height="24"
          width="24">
      </span>Manage Tutorial &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Category</a>

    <a routerLink="/manage/taxes" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/MFcare Icons/Black/Manage convenience.svg" alt="" height="24"
          width="24">
        <img class="white-icon" src="../../../assets/MFcare Icons/White/Manage convenience.svg" alt="" height="24"
          width="24">
      </span>Extra Taxes</a>


    <a routerLink="/edit-profile" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/little/Chnage Password Black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/little/Change Password.svg" alt="" height="24" width="24">
      </span>Change Password</a>



    <a routerLinkActive="active" (click)="openModal(template)" id="logout">
      <span class="sidebar-icon">
        <!-- <img class="black-icon" src="../../../assets/MFcare Icons/Black/Manage tutorial.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/MFcare Icons/White/Manage tutorial.svg" alt="" height="24" width="24"> -->
        <img class="black-icon" src="../../../assets/little/Log Out Black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/little/Log Out.svg" alt="" height="24" width="24">
      </span>Logout</a>



    <!-- Logout fonfirm -->
    <ng-template #template>
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center delete-modal align-items-center">
        <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
        <p>Are you sure you <br>want to logout</p>
        <div class="modal-btn d-flex justify-content-around">
          <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
          <button class="btn btn-blue" (click)="confirm()">Yes</button>
        </div>
      </div>
    </ng-template>

  </div>
</div>