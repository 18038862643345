<div id="forgot-password">
    <div class="container-fluid">
        <div class="row">
            <div class="logo"><img routerLink="/" src="../../../../assets/mf_homecare/left_mf_logo.svg" alt="logo">
            </div>
            <div class="col-md-12">
                <div class="login-field">
                    <div class="login-box">
                        <h3>Two-Step Verification</h3>
                        <p class="mb-1">
                            Check your mobile device for code: XXX-XXX-{{phoneNumber}}
                        </p>
                        <form [formGroup]="otpForm">
                            <div class="form-group">
                                <label for="email">Your Verification Code</label>
                                <span class="input-icon"><img src="../../../../assets/icons/change-pass.svg" alt=""
                                        style="margin-top: -11px;"></span>
                                <input (keypress)="numberOnly($event)" maxlength="7" type="text" class="form-control"
                                    placeholder="7-digit Code" id="otp" formControlName="otp">
                                <div *ngIf="submitted && f.otp.errors" class="alert alert-danger">
                                    <span *ngIf="f.otp.errors.required">
                                        OTP is required.
                                    </span>
                                </div>
                            </div>
                            <div class="not-recieve mt-3"><span *ngIf="isResendDisabled">Resend in {{timerText }}</span>
                                &nbsp;&nbsp;&nbsp; Didn’t receive OTP? <a id="otp" (click)="login()"
                                    [ngClass]="{ 'disabled': isResendDisabled }">Resend</a></div>
                            <button [disabled]="otpForm.get('otp').value?.length !== 7" type="button"
                                class="btn btn-primary" (click)="resetPassword()">Sign in securely</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>