<div id="auth-width">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <div class="login-banner">
          <img src="../../../../assets/mf_homecare/Frame 340284.svg" alt="login-banner" class="img-fluid">
        </div>
      </div>
      <div class="col-md-6">
        <div class="login-field">
          <div class="login-box">
            <h3>Sign In</h3>
            <!-- <p class="mb/ss-3">Promoting skilled trades and technologies in Ontario since 1989.</p> -->
            <form [formGroup]="loginForm" (submit)="login()">
              <div class="form-group">
                <label for="email">Email Address *</label>
                <span class="input-icon"><img src="../../../../assets/icons/email.svg" alt="" style="margin-top: -10px;"></span>
                <input type="email" class="form-control" placeholder="Email" id="email" formControlName="email">
                <div *ngIf="submitted && f.email.errors" class="alert alert-danger">
                  <span *ngIf="f.email.errors.required">
                    Email Required
                  </span>
                  <span *ngIf="f.email.errors.email">
                    Invalid Email
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="email">Password *</label>
                <span class="input-icon"><img src="../../../../assets/icons/password.svg" alt="" style="margin-top: -10px;"></span>
                <input type="password" class="form-control" placeholder="Password" id="password"
                    formControlName="password">
                  <div *ngIf="submitted && f.password.errors" class="alert alert-danger">
                    <span *ngIf="f.password.errors.required">
                      Password Required
                    </span>
                    <span *ngIf="f.password.errors.minlength">
                      Password must be of six or more characters
                    </span>
                </div>

                <!-- <mat-form-field appearance="fill" class="w-100">
                  <span class="input-icon"><img src="../../../../assets/icons/password.svg" alt="" style="margin-top: -40px;"></span>
                  <input matInput [type]="hide2 ? 'password' : 'text'" value=""
                      formControlName="password" class="form-control">
                  <button mat-icon-button matSuffix (click)="hide2 = !hide2"
                      [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                      <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}<i class='fas fa-eye'>fa-eye</i></mat-icon>
                  </button>
                  <div class="alert alert-danger" *ngIf="submitted && f.password.errors">
                      <span *ngIf="f.password.errors.required">
                        Password Required
                      </span>
                      <span *ngIf="f.password.errors.minlength">
                          Password must have at least 6 characters.
                      </span>
                  </div>
              </mat-form-field> -->

                <div class="forgot-pass text-right mt-2" routerLink="/auth/forgot-password">Forgot Password?</div>
              </div>
              <!-- <div class="form-group users-type">
                <p>Please Select User Type : </p>
                <div class="row">
                  <div class="col-md-4">
                    <div class="select-user" [class.selected]="check===1">
                      <img src="../../../../assets/images/admin.svg" alt=""><br>
                      <input type="radio" id="html" name="fav_language" value="1" checked="checked"
                        (change)="onAdminSelection($event)">
                        <label for="admin">Admin</label>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="select-user" [class.selected]="check===2">
                      <img src="../../../../assets/images/customer.svg" alt=""><br>
                      <input type="radio" id="css" name="fav_language" value="5" (change)="onSupportSelection($event)">
                        <label for="support">Customer Support</label>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="select-user" [class.selected]="check===3">
                      <img src="../../../../assets/images/customer.svg" alt=""><br>
                      <input type="radio" id="zone" name="fav_language" value="6" (change)="onZoneSelection($event)">
                        <label for="support">Zone Manager</label>
                    </div>
                  </div>
                </div>
              </div> -->

              <button type="submit" class="btn btn-primary">Sign In</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>